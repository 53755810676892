$border-radius: 1.3rem !default;
$primary: #0032ff;
$primary-light: #c8d2ff;
$header-color: #ffffff;
$header-text-color: #0032FF;
$header-icons-color: #130F26;
$chart-color-first: #0032FF;
$chart-color-second: #00D2FC;
$chart-color-third: #00DCAC;
$chart-border-radious: 16;
$chart-border-radious-mobile: 16;
$spot-rental-img-path: '../../../customization/assets/campaigns/spot-rental-ads.jpg';
$target-based-img-path: '../../../customization/assets/campaigns/target-based-ads.jpg';
$top-up-transfer-img-path: '../../../customization/assets/topup/transfer.jpg';
$top-up-card-img-path: '../../../customization/assets/topup/card.jpg';
$create-account-img-path: '../../../customization/assets/account/create-account.jpg';
$accept-invitation-img-path: '../../../customization/assets/account/accept-invitation.jpg';
$search-displays-img-path: '../../../customization/assets/campaigns/search-equip.jpg';
