//
// Forms
//


// Include Form Components
@import "forms/base";

mat-form-field.adn-form-field {
	width: 100%;
	margin-top: 3px !important;

	&.mat-form-field-type-mat-select:not(.mat-form-field-disabled) {
		.mat-form-field-flex {
			background: #f3f6f9 !important;
			height: 45.5px;
			@include border-radius($border-radius);
		}
	}
	&.mat-form-field-appearance-outline {
		.mat-form-field-wrapper {
			margin: 0;
			padding: 0;
		}
		.mat-form-field-infix {
			padding: 0.75em 0 0.75em 0;

			.mat-select-trigger {
				.mat-select-value {
					transform: translateY(-4px) !important;
					.mat-select-value-text {
						padding-left: 8px;
						font-size: 1.08rem;
					}
					.mat-select-placeholder {
						padding-left: 8px;
						font-size: 1.08rem;
					}
				}
			}
		}
		.mat-form-field-outline-end {
			border-color: transparent;
		}
		.mat-form-field-outline-start {
			border-color: transparent;
		}
	}
}

mat-form-field.adn-form-field-white {
	width: 100%;
	margin-top: 3px !important;

	&.mat-form-field-type-mat-select:not(.mat-form-field-disabled) {
		.mat-form-field-flex {
			background: #fff !important;
			height: 45.5px;
			@include border-radius($border-radius);
		}
	}
	&.mat-form-field-appearance-outline {
		.mat-form-field-wrapper {
			margin: 0;
			padding: 0;
		}
		.mat-form-field-infix {
			padding: 0.75em 0 0.75em 0;

			.mat-select-trigger {
				.mat-select-value {
					transform: translateY(-4px) !important;
					.mat-select-value-text {
						padding-left: 8px;
						font-size: 1.08rem;
					}
					.mat-select-placeholder {
						padding-left: 8px;
						font-size: 1.08rem;
					}
				}
			}
		}
		.mat-form-field-outline-end {
			border-color: transparent;
		}
		.mat-form-field-outline-start {
			border-color: transparent;
		}
	}
}

mat-form-field.adn-mat-form-field2 {
	width: 100%;
	.mat-form-field-wrapper {
		padding-bottom: 0 !important;
		.mat-form-field-underline {
			display: none;
		}
	}
}

mat-form-field.adn-mat-form-field2.mat-form-field-appearance-fill .mat-form-field-flex {
	background-color: #F3F6F9 !important;
	@include border-radius($border-radius);
	padding: 0.45em 0.75em;
}

mat-form-field.adn-mat-form-field2.mat-form-field-appearance-fill .mat-form-field-infix {
	padding: 0 !important;
	border-top: 0 !important;
	line-height: 7px;
	margin-left: 6px;
	width: 100%;
}

mat-datepicker-content.mat-datepicker-content {
	@include border-radius($border-radius);
	box-shadow: 0 0 0 0 rgb(0 0 0 / 20%), 0 0 0 0 rgb(0 0 0 / 14%), 0 0 36px 0 rgb(0 0 0 / 12%);
}

mat-datepicker-content.mat-datepicker-content .mat-calendar {
	height: auto;
}

input[type="color"]:first-child {
	padding: 0;
	margin: 0;
	border: none;
	box-shadow: none;
	border-radius: $border-radius;
	background: none;
}

input[type="color"]::-webkit-color-swatch-wrapper {
	padding: 0;
}

input[type="color"]::-webkit-color-swatch {
	border: none;
	border-radius: $border-radius;
}

input[type="color"]:nth-child(2) {
	padding: 0;
	margin: 0;
	border: none;
	box-shadow: none;
	border-radius: $border-radius;
	background: #fff;
	outline: none;
}

input[type="color" i] {
	border-radius: $border-radius;
	border: none;
	height: 24px;
	width: 40px;
	background: none;
}

mat-accordion.adn-acordion-schedules {
	display: inline !important;
	color: #282828 !important;
	font-family: Axiforma, "sans-serif" !important;

	mat-expansion-panel {
		@include border-radius($border-radius);
		display: inline-block !important;
		width: 100%;
		background-color: #F3F6F9 !important;
		box-shadow: none;
		font-family: Axiforma, "sans-serif" !important;
		.mat-expansion-panel-header {
			font-family: Axiforma, "sans-serif" !important;
			.mat-expansion-panel-header-title {
				color: #282828 !important;
			}
			.mat-expansion-panel-header-description {
				color: #282828 !important;
			}
			.mat-expansion-indicator {
				color: #282828 !important;
				&:after {
					color: #282828 !important;
				}
			}
		}
		&:first-of-type {
			border-top-right-radius: $border-radius !important;
			border-top-left-radius: $border-radius !important;
		}
		&:last-of-type {
			border-bottom-right-radius: $border-radius !important;
			border-bottom-left-radius: $border-radius !important;
		}
	}
}

.radio>input:checked~span {
	background-color: $primary;
}
.radio.radio-accent.radio-primary > span:after {
	background-color: #dae0e6 !important;
}
.radio.radio-primary > input:checked ~ span:after {
	background-color: $primary !important;
	border-color: #ffffff;
}

mat-form-field.adn-date-dipslay-analytics {
	width: 100%;
}

mat-form-field.adn-date-dipslay-analytics.mat-form-field-appearance-fill .mat-form-field-flex {
	background-color: rgb(255, 255, 255) !important;
	border-radius: $border-radius;
	padding: 0.3em 0.8em !important;
	@media (max-width: 720px) {
		background-color: #F3F6F9 !important;
	}
}

mat-form-field.adn-date-dipslay-analytics.mat-form-field-appearance-fill .mat-form-field-infix {
	padding: 0;
	border: 0;
}

mat-form-field.adn-date-dipslay-analytics.mat-form-field-appearance-fill .mat-form-field-ripple {
	display: none;
}

mat-form-field.adn-date-dipslay-analytics.mat-form-field-underline {
	display: none;
}

mat-form-field.adn-date-dipslay-analytics.mat-form-field-wrapper {
	padding: 0;
}

mat-form-field.adn-date-dipslay-analytics mat-datepicker-content.mat-datepicker-content .mat-calendar {
	width: 330px;
	height: auto;
}

mat-form-field.adn-date-dipslay-analytics.mat-form-field-appearance-fill .mat-form-field-underline::before {
	display: none;
}

mat-form-field.adn-date-dipslay-analytics mat-datepicker-toggle.mat-datepicker-toggle-active {
	color: $primary;
}

mat-form-field.adn-date-dipslay-analytics mat-date-range-input.mat-date-range-input {
	position: absolute;
	width: 100%;
	top: -16px;
	left: 5px;
}

mat-form-field.adn-date-dipslay-analytics .adn-date-inpput {
	position: absolute;
	width: 100%;
	top: -16px;
	left: 5px;
}

mat-datepicker-content.mat-datepicker-content {
	border-radius: $border-color;
	box-shadow: 0 0 0 0 rgb(0 0 0 / 20%), 0 0 0 0 rgb(0 0 0 / 14%), 0 0 36px 0 rgb(0 0 0 / 12%);
}

mat-datepicker-content.mat-datepicker-content .mat-calendar {
	height: auto;
	.mat-calendar-body-cell-content {
		border-radius: $border-radius;
		border-width: 0;
		border-style: none;
	}
}

// ---------------------------------------------
// input dates in campaign information component
// ---------------------------------------------

mat-form-field.adn-mat-form-field-info-campaign {
	width: 100%;
}
mat-form-field.adn-mat-form-field-info-campaign.mat-form-field-appearance-fill .mat-form-field-flex {
	background-color: #fff !important;
	border-radius: $border-radius !important;
	padding: 1rem;
	flex-direction: row-reverse;
}
mat-form-field.adn-mat-form-field-info-campaign:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button {
	transform: translateY(1px) !important;
}
mat-form-field.adn-mat-form-field-info-campaign.mat-form-field-appearance-fill .mat-form-field-infix {
	padding: 0;
	border: 0;
}
mat-form-field.adn-mat-form-field-info-campaign.mat-form-field-appearance-fill .mat-form-field-ripple {
	display: none;
}
mat-form-field.adn-mat-form-field-info-campaign.mat-form-field-underline {
	display: none;
}
mat-form-field.adn-mat-form-field-info-campaign.mat-form-field-wrapper {
	padding: 0;
}
mat-form-field mat-datepicker-content.mat-datepicker-content .mat-calendar {
	width: 330px;
	height: auto;
	.mat-calendar-body-cell-content {
		border-radius: $border-radius;
		border-width: 0;
		border-style: none;
	}
}
mat-form-field.adn-mat-form-field-info-campaign.mat-form-field-appearance-fill .mat-form-field-underline::before {
	display: none;
}
mat-form-field.adn-mat-form-field-info-campaign mat-datepicker-toggle.mat-datepicker-toggle-active {
	color: $primary;
}
mat-form-field.adn-mat-form-field-info-campaign mat-date-range-input.mat-date-range-input {
	position: absolute;
	width: 100%;
	top: -16px;
	left: 5px;
}
mat-form-field.adn-mat-form-field-info-campaign .adn-date-inpput {
	position: absolute;
	width: 100%;
	top: -16px;
	left: 5px;
}
mat-form-field.adn-mat-form-field-info-campaign mat-date-range-input.mat-date-range-input .mat-date-range-input-container .mat-date-range-input-start-wrapper {
	height: 1.4rem;
}
mat-form-field.adn-mat-form-field-info-campaign mat-date-range-input.mat-date-range-input .mat-date-range-input-container {
	display: flex;
	align-items: baseline;
}


// ---------------------------------------------
// filters display gallery
// ---------------------------------------------

mat-form-field.adn-mat-form-field {
	width: 100%;
}
mat-form-field.adn-mat-form-field.mat-form-field-appearance-fill .mat-form-field-flex {
	background-color: rgb(243 246 249) !important;
	border-radius: $border-radius !important;
	padding: 1rem;
}
mat-form-field.adn-mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button {
	transform: translateY(1px) !important;
}
mat-form-field.adn-mat-form-field.mat-form-field-appearance-fill .mat-form-field-infix {
	padding: 0;
	border: 0;
}
mat-form-field.adn-mat-form-field.mat-form-field-appearance-fill .mat-form-field-ripple {
	display: none;
}
mat-form-field.adn-mat-form-field.mat-form-field-underline {
	display: none;
}
mat-form-field.adn-mat-form-field.mat-form-field-wrapper {
	padding: 0;
}
mat-form-field.adn-mat-form-field mat-datepicker-content.mat-datepicker-content .mat-calendar {
	width: 330px;
	height: auto;
}
mat-form-field.adn-mat-form-field.mat-form-field-appearance-fill .mat-form-field-underline::before {
	display: none;
}
mat-form-field.adn-mat-form-field mat-datepicker-toggle.mat-datepicker-toggle-active {
	color: $primary;
}
mat-form-field.adn-mat-form-field mat-date-range-input.mat-date-range-input {
	position: absolute;
	width: 100%;
	top: -16px;
	left: 5px;
}
mat-form-field.adn-mat-form-field .adn-date-inpput {
	position: absolute;
	width: 100%;
	top: -16px;
	left: 5px;
}

mat-form-field.adn-mat-form-field2 {
	width: 100%;
}
mat-form-field.adn-mat-form-field2.mat-form-field-appearance-fill .mat-form-field-flex {
	background-color: #fff !important;
	border-radius: $border-radius !important;
	padding: 1rem;
}
mat-form-field.adn-mat-form-field2:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button {
	transform: translateY(1px) !important;
}
mat-form-field.adn-mat-form-field2.mat-form-field-appearance-fill .mat-form-field-infix {
	padding: 0;
	border: 0;
}
mat-form-field.adn-mat-form-field2.mat-form-field-appearance-fill .mat-form-field-ripple {
	display: none;
}
mat-form-field.adn-mat-form-field2.mat-form-field-underline {
	display: none;
}
mat-form-field.adn-mat-form-field2.mat-form-field-wrapper {
	padding: 0;
}
mat-form-field.adn-mat-form-field2.mat-form-field-appearance-fill .mat-form-field-underline::before {
	display: none;
}
mat-form-field.adn-mat-form-field2 mat-datepicker-toggle.mat-datepicker-toggle-active {
	color: $primary;
}
mat-form-field.adn-mat-form-field2 mat-date-range-input.mat-date-range-input {
	position: absolute;
	width: 100%;
	top: -16px;
	left: 5px;
}
mat-form-field.adn-mat-form-field2 .adn-date-inpput {
	position: absolute;
	width: 100%;
	top: -16px;
	left: 5px;
}
mat-form-field.adn-mat-form-field2 mat-date-range-input.mat-date-range-input .mat-date-range-input-container .mat-date-range-input-start-wrapper {
	height: 1.4rem;
}
mat-form-field.adn-mat-form-field-select.mat-form-field-appearance-fill .mat-form-field-flex {
	background-color: rgb(243 246 249) !important;
	border-radius: $border-radius;
	padding: 1em;
	.mat-select-arrow-wrapper {
		display: flex;
	}
	.mat-form-field-underline {
		display: none;
	}
}
mat-form-field.adn-mat-form-field-select2.mat-form-field-appearance-fill .mat-form-field-flex {
	background-color: #fff !important;
	border-radius: $border-radius;
	padding: 1em;
	.mat-select-arrow-wrapper {
		display: flex;
	}
	.mat-form-field-underline {
		display: none;
	}
}
mat-form-field.adn-mat-form-field-select.mat-form-field-appearance-fill .mat-form-field-underline {
	display: none;
}
mat-form-field.adn-mat-form-field-select2.mat-form-field-appearance-fill .mat-form-field-underline {
	display: none;
}
mat-form-field.adn-mat-form-field-select.mat-form-field-appearance-fill .mat-form-field-infix {
	padding: 0;
	border: 0;
}
mat-form-field.adn-mat-form-field-select2.mat-form-field-appearance-fill .mat-form-field-infix {
	padding: 0;
	border: 0;
}
mat-form-field.adn-mat-form-field-select.mat-form-field-appearance-fill .mat-form-field-ripple {
	display: none;
}
mat-form-field.adn-mat-form-field-select2.mat-form-field-appearance-fill .mat-form-field-ripple {
	display: none;
}
mat-form-field.adn-mat-form-field-select.mat-form-field-appearance-fill .mat-select-arrow-wrapper {
	transform: translateY(0);
}
mat-form-field.adn-mat-form-field-select2.mat-form-field-appearance-fill .mat-select-arrow-wrapper {
	transform: translateY(0);
}
mat-form-field.adn-mat-form-field-select.mat-form-field-underline {
	display: none;
}
mat-form-field.adn-mat-form-field-select2.mat-form-field-underline {
	display: none;
}

// ---------------------------------------------
// input ngx slider
// ---------------------------------------------

.adn-ngx-slider {
	.ngx-slider {
		.ngx-slider-bar {
			background: #f3f6f9 !important;
			height: 10px;
			border-radius: $border-radius;
		}
		.ngx-slider-selection {
			background: $primary !important;
		}
		.ngx-slider-pointer {
			cursor: pointer;
			width: 22px;
			height: 22px;
			top: -6px;
			background-color: $primary;
			z-index: 3;
			border-radius: 100%;
			&::after {
				display: none;
			}
		}
		.ngx-slider-bubble {
			font-size: 14px;
			.ngx-slider-limit {
				color: #cbcbcb;
				font-size: 12px;
			}
		}
	}
	.ngx-slider .ngx-slider-bubble.ngx-slider-limit {
		color: #cfcfcf;
		font-size: 12px;
	}
}

// ---------------------------------------------
// input ngx slider admin client
// ---------------------------------------------

.adn-ngx-slider-admin-client {
	width: 100%;
	.ngx-slider {
		.ngx-slider-bar {
			background: #f5faff !important;
			height: 7px;
			border-radius: 4px;
		}
		.ngx-slider-selection {
			background: $primary !important;
		}
		.ngx-slider-inner-tooltip {
			height: 100%;
			transform: translateY(10px);
			color: $primary-light;
		}
		.ngx-slider-tick {
			text-align: center;
			cursor: pointer;
			width: 13px;
			height: 13px;
			background: $primary-light;
			border-radius: 50%;
			position: absolute;
			top: 0;
			left: 0;
			margin-left: 0;
		}
		.ngx-slider-pointer {
			cursor: pointer;
			width: 14px;
			height: 14px;
			top: -4px;
			background-color: $primary !important;
			z-index: 3;
			border-radius: 100%;
			&::after {
				display: none;
			}
		}
		.ngx-slider-bubble {
			font-size: 12px;
			.ngx-slider-limit {
				color: $primary-light;
				font-size: 12px;
			}
		}
	}
}

// ---------------------------------------------
// mat-form-fiel dates - fastbuy campaign
// ---------------------------------------------

mat-form-field.adn-mat-form-field-fastbuy-date {
	width: 100%;
	.mat-form-field-wrapper {
		padding-bottom: 0 !important;
		.mat-form-field-underline {
			display: none;
		}
	}
}
mat-form-field.adn-mat-form-field-fastbuy-date.mat-form-field-appearance-fill .mat-form-field-flex {
	background-color: #F3F6F9 !important;
	@include border-radius($border-radius);
	padding: 0.45em 0.75em;
}
mat-form-field.adn-mat-form-field-fastbuy-date.mat-form-field-appearance-fill .mat-form-field-infix {
	padding: 0 !important;
	border-top: 0 !important;
	line-height: 7px;
	margin-left: 6px;
	width: 100%;
}
mat-form-field.adn-mat-form-field-fastbuy-date {
	width: 100%;
}
mat-form-field.adn-mat-form-field-fastbuy-date:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button {
	transform: translateY(1px) !important;
}
mat-form-field.adn-mat-form-field-fastbuy-date.mat-form-field-appearance-fill .mat-form-field-infix {
	padding: 0;
	border: 0;
}
mat-form-field.adn-mat-form-field-fastbuy-date.mat-form-field-appearance-fill .mat-form-field-ripple {
	display: none;
}
mat-form-field.adn-mat-form-field-fastbuy-date.mat-form-field-underline {
	display: none;
}
mat-form-field.adn-mat-form-field-fastbuy-date.mat-form-field-wrapper {
	padding: 0;
}
mat-form-field.adn-mat-form-field-fastbuy-date.mat-form-field-appearance-fill .mat-form-field-underline::before {
	display: none;
}
mat-form-field.adn-mat-form-field-fastbuy-date mat-datepicker-toggle.mat-datepicker-toggle-active {
	color: $primary;
}
mat-form-field.adn-mat-form-field-fastbuy-date mat-date-range-input.mat-date-range-input {
	position: absolute;
	width: 100%;
	top: -16px;
	left: 5px;
}
mat-form-field.adn-mat-form-field-fastbuy-date .adn-date-inpput {
	position: absolute;
	width: 100%;
	top: -16px;
	left: 5px;
}
mat-form-field.adn-mat-form-field-fastbuy-date mat-date-range-input.mat-date-range-input .mat-date-range-input-container .mat-date-range-input-start-wrapper {
	height: 1.4rem;
}


// ---------------------------------------------
// mat-form-fiel dates - export report
// ---------------------------------------------

mat-form-field.adn-mat-form-field-rel-export {
	width: 100%;
}
mat-form-field.adn-mat-form-field-rel-export:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button {
	transform: translateY(1px) !important;
}
mat-form-field.adn-mat-form-field-rel-export.mat-form-field-appearance-fill .mat-form-field-infix {
	padding: 0;
	border: 0;
}
mat-form-field.adn-mat-form-field-rel-export.mat-form-field-appearance-fill .mat-form-field-ripple {
	display: none;
}
mat-form-field.adn-mat-form-field-rel-export.mat-form-field-underline {
	display: none;
}
mat-form-field.adn-mat-form-field-rel-export.mat-form-field-wrapper {
	padding: 0;
}
mat-form-field.adn-mat-form-field-rel-export mat-datepicker-content.mat-datepicker-content .mat-calendar {
	width: 330px;
	height: auto;
}
mat-form-field.adn-mat-form-field-rel-export.mat-form-field-appearance-fill .mat-form-field-underline::before {
	display: none;
}
mat-form-field.adn-mat-form-field-rel-export mat-datepicker-toggle.mat-datepicker-toggle-active {
	color: #0032ff;
}
mat-form-field.adn-mat-form-field-rel-export mat-date-range-input.mat-date-range-input {
	position: absolute;
	width: 100%;
	top: -16px;
	left: 5px;
}
mat-form-field.adn-mat-form-field-rel-export .adn-date-inpput {
	position: absolute;
	width: 100%;
	top: -16px;
	left: 5px;
}
mat-form-field.adn-mat-form-field-rel-export.mat-form-field-appearance-fill .mat-form-field-flex {
	background-color: #F3F6F9 !important;
	border-radius: $border-radius;
	padding: 1em;
	.mat-select-arrow-wrapper {
		display: flex;
	}
	.mat-form-field-underline {
		display: none;
	}
}
mat-form-field.adn-mat-form-field-rel-export.mat-form-field-appearance-fill .mat-form-field-underline {
	display: none;
}
mat-form-field.adn-mat-form-field-rel-export.mat-form-field-appearance-fill .mat-form-field-infix {
	padding: 0;
	border: 0;
}
mat-form-field.adn-mat-form-field-rel-export.mat-form-field-appearance-fill .mat-form-field-ripple {
	display: none;
}
mat-form-field.adn-mat-form-field-rel-export.mat-form-field-appearance-fill .mat-select-arrow-wrapper {
	transform: translateY(0);
}
mat-form-field.adn-mat-form-field-rel-export.mat-form-field-underline {
	display: none;
}
