/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700|Roboto:300,400,500,600,700");
// Global vendors
@import "~bootstrap/dist/css/bootstrap.css";
// => Material
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";

@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Global fonts
@import "./assets/fonts/Axiforma/axiforma-font.css";
@import "~socicon/css/socicon.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "./assets/plugins/flaticon/flaticon.css";
@import "./assets/plugins/flaticon2/flaticon.css";
@import "./assets/plugins/keenthemes-icons/font/ki.css";

// Metronic styles (replace these path when using RTL css below)
@import "./assets/sass/style.angular.scss";

// For RTL, check this documentation https://keenthemes.com/metronic/?page=docs&section=angular-rtl
// @import "./assets/sass/style.angular.rtl.css";

// Default Layout themes
@import "./assets/sass/themes/layout/header/base/light.scss";
@import "./assets/sass/themes/layout/header/menu/light.scss";
@import "./assets/sass/themes/layout/brand/light.scss";
@import "./assets/sass/themes/layout/aside/light.scss";

// Plugins
@import "~ngx-tagify/styles/tagify";

// Header themes
// .header-base-light {
//     @import "./assets/sass/themes/layout/header/base/light.scss";
// }

// .header-base-dark {
//     @import "./assets/sass/themes/layout/header/base/dark.scss";
// }

// Header Menu themes
// .header-menu-light {
//     @import "./assets/sass/themes/layout/header/menu/light.scss";
// }
// .header-menu-dark {
//     @import "./assets/sass/themes/layout/header/menu/dark.scss";
// }

// Brand themes
//.brand-dark {
//  @import "./assets/sass/themes/layout/brand/dark.scss";
//}

.brand-light {
    @import "./assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// .aside-dark {
//   @import "./assets/sass/themes/layout/aside/dark.scss";
// }

// .aside-light {
//   @import "./assets/sass/themes/layout/aside/light.scss";
// }

@import "./assets/sass/pages/wizard/wizard-5";

.wizard-title {
    font-size: 1rem !important;
}

.wizard-desc {
    font-size: 80% !important;
}

.wizard-step {
    margin-bottom: 0.4rem !important;
    cursor: default;
}

.wizard-step[data-wizard-state="current"] .wizard-icon {
    background-color: #c8d2ff !important;
}

.wizard-step[data-wizard-state="current"] .wizard-number {
    color: #0032ff !important;
}

.wizard-step[data-wizard-state="current"]:last-child .wizard-icon .wizard-check {
    display: none !important;
}

.wizard-step[data-wizard-state="current"]:last-child .wizard-icon .wizard-number {
    display: block !important;
}

.wizard-icon {
    min-width: 40px !important;
    height: 40px !important;
    background-color: #F3F6F9 !important;
}

.wizard-step[data-wizard-state=done] .wizard-icon {
    background-color: #c8d2ff !important;
}

.wizard-step[data-wizard-state=done] .wizard-icon .wizard-check {
    color: #0032ff !important;
    font-size: 1rem !important;
}

.wizard-number {
    font-size: 1rem !important;
    color: #3f4254 !important;
}

// hide bootstrap dropdown arrow globally
.dropdown-toggle::after {
    display: none !important;
}

.angular-bootstrap-table {
    overflow-x: auto;
}

.angular-bootstrap-table {
    th {
        outline: none;

        &.sortable {
            .svg-icon-sort {
                opacity: 0;
            }

            &:hover {
                cursor: pointer;

                .svg-icon-sort {
                    opacity: 1;
                }
            }
        }
    }

    .table.table-head-custom thead th.sortable-active {
        color: $primary !important;
    }
}

.cursor-default {
    cursor: default !important;
}

.progress-modal {
    height: 3px;
    width: 100%;
}

#kt_content {
    height: auto;
}

.no-margin {
    margin: 0;
}

.leaflet-container {
    z-index: -1;
}

.cdk-overlay-container { z-index: 10000 !important; }
.cdk-overlay-connected-position-bounding-box { z-index: 10000000 !important; }

.visually-hidden {
	display: none;
}

.adn-font-title {
	font-size: 40px;
	color: #252836;
	@media (max-width: 720px) {
		font-size: 30px;
	}
}

.btn {
	//border-radius: 1.2rem !important;
}

.rounded {
	//border-radius: 1.857rem !important;
}

.btn.btn-primary:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-primary:focus:not(.btn-text), .btn.btn-primary.focus:not(.btn-text) {
	//color: #FFFFFF;
	//background-color: #0031e1;
	//border-color: #0032ff;
}

.adn-err-max-w {
	max-width: 400px;
}

.card, .form-group, .btn {
	font-family: Axiforma, sans-serif;
}

.mat-expansion-panel-content {
	font: 400 14px / 20px Axiforma, sans-serif !important;
}

.adn-btn-opacity-hover {
	opacity: 0.9;
	&:hover {
		opacity: 1;
	}
}
