//
// Tagify
//


.tagify {
    @include border-radius($border-radius);
    border-color: $input-border-color;
    padding: 0;
    display: flex;
    height: auto;

    &:hover {
        border-color: $input-border-color;
    }

    &.tagify--focus {
        border: 1px solid $input-focus-border-color;
    }

    .tagify__tag {
        background-color: $gray-200;
        display: inline-flex;
        align-items: center;
        padding: 0 0.5rem;
        @include border-radius($border-radius);

        div {
            order: 1;
            padding-right: 0.25rem;

            .tagify__tag-text {
                color: $dark-50;
                font-weight: 400;
            }

            &:before {
                box-shadow: none !important;
                animation: none;
                transition: none;
            }
        }

        .tagify__tag__removeBtn {
            display: flex;
            align-items: center;
            order: 2;
            transform: none;
            position: static;
            border-radius: 0;
            margin-left: 0.25rem;
            margin-right: 0;
            line-height: 0;

            &:after {
                transition: none !important;
                line-height: 0;
                @include ki(get($ki-types, close));
                font-size: 0.6rem;
                color: $text-muted;
            }

            &:hover {
                background: transparent;
                color: $primary;

                & + div > span {
                    opacity: .4;
                }

                &:after {
                    transition: none !important;
                    color: $primary;
                }
            }
        }



        // State colors
        @each $name, $color in $theme-colors {
            &.tagify__tag--#{$name}:not(.tagify--notAllowed) {
                 background-color: $color;

                 div {
                     .tagify__tag-text {
                         color: $white;
                         font-weight: 500;
                     }
                 }

                 .tagify__tag__removeBtn {
                     &:after {
                         color: $white;
                     }

                     &:hover {
                         background: transparent;
                         color: $white;

                         & + div > span {
                             opacity: .3;
                         }
                     }
                 }
             }

             &.tagify__tag-light--#{$name}:not(.tagify--notAllowed) {
                  background-color: rgba($color, 0.12);

                  div {
                      .tagify__tag-text {
                          color: $color;
                          font-weight: 500;
                      }
                  }

                  .tagify__tag__removeBtn {
                      &:after {
                          color: $color;
                      }

                      &:hover {
                          background: transparent;
                          color: $white;

                          & + div > span {
                              opacity: .3;
                          }
                      }
                  }
             }
        }

        // Not Allowed Mode
        &.tagify--notAllowed {
            background-color: $danger;

            div {
                &::before {
                    box-shadow: none !important;
                    animation: none;
                    transition: none;
                }

                .tagify__tag-text {
                    color: $danger-inverse;
                    opacity: .75;
                }
            }

            .tagify__tag__removeBtn {
                &:after {
                    opacity: .5;
                    color: $danger-inverse;
                }

                &:hover {
                    background: transparent;
                    color: $danger-inverse;

                    & + div > span {
                        opacity: .3;
                    }
                }
            }
        }
    }
}

.tagify[readonly] .tagify__tag__removeBtn {
    display: none;
}

.tagify__input {
    color: $input-plaintext-color;

    &::before {
        top: auto;
        bottom: auto;
        height: auto;
        position: relative;
        display: none !important;
    }
}

.tagify__input ~ .tagify.form-control {
    margin: 1rem;
    padding: 0;
    @include button-reset();
}

.tagify__input.form-control {
    margin: 0;
}

.tagify__input--outside{
    display: block;
    max-width: 600px;
    border: 1px solid $gray-200;
    margin-top: 1.5em;
    margin-bottom: 1em;
}

.tagify__dropdown {
    //box-shadow: $dropdown-box-shadow;
    background: $white;
    border: 0 !important;
    outline: none !important;
    padding: 0.75rem 0;
    z-index: $zindex-dropdown;
    @include border-radius($border-radius);

    .modal-open & {
        z-index: $zindex-modal + 1;
    }

    .tagify__dropdown__wrapper {
        border: 0 !important;
        outline: none !important;
        box-shadow: none;
    }

    .tagify__dropdown__item {
        color: $dark-75;
        border-radius: 0;
        padding: 0.75rem 1.5rem;
        margin: 0;
        box-shadow: none;

        &.tagify__dropdown__item--active {
            background: $gray-100;
            color: $primary;
        }
    }
}

.tagify {
	border-radius: $border-radius !important;
    background-color: #F3F6F9 !important;
    border-color: #F3F6F9 !important;
    min-height: 42px !important;
    padding: 5px !important;
	.tagify__input {
		padding-top: 0.4rem !important;
	}
	.tagify__tag .tagify__tag__removeBtn:hover {
		background: none !important;
		color: #0032ff;
	}
}

.tagify.tagify2 {
    .tagify {
        width: 100%;
        background-color: #ffffff !important;
        border-color: #fbfbfb !important;
        min-height: 42px !important;
        padding: 5px !important;
    }
    .tagify.tagify--focus {
        border: 0.2rem solid $primary !important;
        transition: none !important;
    }
}

.tagify.tagify3 {
	padding: 0 !important;
	.tagify {
		width: 100%;
		background-color: $light !important;
		border-color: $light !important;
		min-height: 42px !important;
		padding: 0 !important;
	}
	.tagify.tagify--focus {
		border: 0.2rem solid $primary !important;
		transition: none !important;
	}
}

.tagify .tagify--focus {
    border: 0.2rem solid $primary !important;
}

.tagify .tagify__tag {
	border-radius: $border-radius !important;
    background-color: $primary;
    z-index: 0;
	div {
		padding-top: 0.5rem;
	}
}

.tagify .tagify__tag div .tagify__tag-text {
    color: #fff;
    font-weight: 400;
}

.tagify .tagify__tag .tagify__tag__removeBtn:after {
    color: #fff;
}

.tagify .tagify__tag .tagify__tag__removeBtn {
	&:hover{
		color: white;
		background: none;
		background: var(--tag-remove-btn-bg--hover, white);
		+ span { box-shadow: 0 0 0 2px white inset; transition:.1s; }
		+ div {
			> span { opacity:.5; }
			&::before{
				box-shadow: none !important;
				transition: none !important;
			}
		}
	}
}

// TODO:remove the margin that appears on repeated tags and that are automatically erased (not completed)!
.tagify .tagify__tag.tagify--notAllowed {
	color: white;
	background: #F64E60 !important;
	+ div {
		> span { opacity:.5; }
		&::before{
			box-shadow: none !important;
			transition: none !important;
		}
	}
}

.tagify__tag.tagify--notAllowed div::before {
	box-shadow: none !important;
	transition: none !important;
}
