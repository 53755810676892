//
// SVG
//


// Base
.svg {
    @each $name, $color in $theme-text-colors  {
        &.svg-fill-#{$name} {
            fill: $color !important;
        }

        &.svg-fill-light-#{$name} {
            fill: theme-light-color($name) !important;
        }
    }
}

.adn-nav-ico-clients {
    filter: saturate(0) brightness(3);
}

.adn-nav-ico-users {
	filter: saturate(0) brightness(3);
}
