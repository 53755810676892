//
// Wizard
//

.wizard {
	// Step info
	[data-wizard-type="step-info"] {
		display: none;

		&[data-wizard-state="current"] {
			display: block;
		}
	}

	// Content states
	[data-wizard-type="step-content"] {
		display: none;

		&[data-wizard-state="current"] {
			display: block;
		}
	}

	// Buttons states
	& {
		[data-wizard-type="action-prev"] {
			display: none;
		}

		[data-wizard-type="action-next"] {
			display: inline-block;
		}

		[data-wizard-type="action-submit"] {
			display: none;
		}
	}

	&[data-wizard-state="first"] {
		[data-wizard-type="action-prev"] {
			display: none;
		}

		[data-wizard-type="action-next"] {
			display: inline-block;
		}

		[data-wizard-type="action-submit"] {
			display: none;
		}
	}

	&[data-wizard-state="between"] {
		[data-wizard-type="action-prev"] {
			display: inline-block;
		}

		[data-wizard-type="action-next"] {
			display: inline-block;
		}

		[data-wizard-type="action-submit"] {
			display: none;
		}
	}

	&[data-wizard-state="last"] {
		[data-wizard-type="action-prev"] {
			display: inline-block;
		}

		[data-wizard-type="action-next"] {
			display: none;
		}

		[data-wizard-type="action-submit"] {
			display: inline-block;
		}
	}

	// Enable cursor pointer on clickable wizards
	&[data-wizard-clickable="true"] {
		.wizard-step {
			cursor: pointer;
		}
	}
}

#campaign-steps.wizard-label .wizard-title {
	font-size: 1rem !important;
}
#campaign-steps.wizard-label .wizard-desc {
	font-size: 80% !important;
}

#campaign-steps.wizard-step {
	margin-bottom: 0.4rem !important;
	cursor: default;
}

#campaign-steps.wizard-step[data-wizard-state=current] .wizard-icon {
	background-color: $primary-light !important;
}

#campaign-steps.wizard-step[data-wizard-state=current] .wizard-number {
	color: $primary !important;
}

#campaign-steps.wizard-step[data-wizard-state=current] .wizard-label .wizard-title {
	color: #343434 !important;
}

#campaign-steps.wizard-wrapper .wizard-icon {
	min-width: 40px !important;
	height: 40px !important;
	background-color: #ffffff !important;
	border-radius: $border-radius;
}

#campaign-steps.wizard-step[data-wizard-state=done] .wizard-icon {
	background-color: $primary-light !important;
}


#campaign-steps.wizard-step[data-wizard-state=done] .wizard-icon .wizard-check {
	color: $primary !important;
	font-size: 1rem !important;
}

#campaign-steps.wizard-step .wizard-icon .wizard-number {
	font-size: 1rem !important;
	color: $primary !important;
}

.adn-account-backg {
	background-image: linear-gradient(to bottom, $primary 0%, rgba(255, 255, 255, 0) 50%), url($create-account-img-path);
	background-repeat: no-repeat;
	background-size: cover;
	background-position-x: center;
	border-top: 5px solid $primary;
}

.adn-invites-backg {
	background-image: linear-gradient(to bottom, $primary 0%, rgba(255, 255, 255, 0) 50%), url($accept-invitation-img-path);
	background-repeat: no-repeat;
	background-size: cover;
	background-position-x: center;
	border-top: 5px solid $primary;
}
