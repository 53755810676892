//
// Loading
//

.dialog {
    $width: 130px;
    width: $width;
    margin: 0 auto;
    position: fixed;
    z-index: 1100;
    justify-content: center;
    padding:  1rem;
    box-shadow: 0px 3px 12px 0px rgba(0,0,0,0.1);
    background-color: #fff;
    @include border-radius($border-radius);

    // Placement
    &.dialog-top-center {
        display: flex;
        top: -100px;
        left: 50%;
        margin-left: -($width / 2);
        transition: top 0.6s ease;
        @include border-top-left-radius(0);
        @include border-top-right-radius(0);

        &.dialog-shown {
            top: 0px;
            transition: top 0.6s ease;
        }
    }

    // Types
    &.dialog-loader {
        font-size: 1rem;
        padding: 0.75rem;
        font-weight: 400;
        color: $dark-75;
    }
}

.adn-invite-users-dialog .mat-dialog-container {
    transform: translate(448px,-16px) !important;
    box-shadow: 0 0 5px 0 #e2e2e2 !important;
}

.adn-create-instance .mat-dialog-container {
    overflow: hidden;
    padding: 0;
    background-color: transparent;
    box-shadow: none !important;
}

// .cdk-overlay-container {
//     z-index: 2000 !important;
// }

.offcanvas-overlay {
    z-index: 800 !important;
}
