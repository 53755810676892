//
// Pagination
//

.pagination {
	flex:1;
	display:flex;
	justify-content: center;

	.page-link {
		display: flex;
		justify-content: center;
		align-items: center;
		transition: $transition-link;

		&.active,
		&:hover,
		&:focus {
			.page-link{
				transition: $transition-link;
			}
		}
	}
}

ngb-pagination.adn-paginator a {
	color: #70737e !important;
}

ngb-pagination.adn-paginator ul {
	justify-content: start !important;
}

ngb-pagination.adn-paginator .page-item {
	margin-right: 8px;
}

ngb-pagination.adn-paginator .page-item .page-link{
	margin-left: 0;
	@include border-radius($border-radius);
	background-color: #ecf1f6;
	border: none;
	padding: 9px 12px;
	&:hover {
		background-color: $primary;
		color: #fff !important;
	}
}

ngb-pagination.adn-paginator .page-item:first-child .page-link {
	margin-left: 0;
	@include border-radius($border-radius);
	background-color: #ecf1f6;
	border: none;
	&:hover {
		background-color: $primary;
	}
}
ngb-pagination.adn-paginator .page-item:last-child .page-link {
	margin-left: 0;
	@include border-radius($border-radius);
	background-color: #ecf1f6;
	border: none;
	&:hover {
		background-color: $primary;
	}
}

ngb-pagination.adn-paginator .page-item.disabled .page-link{
	opacity: 0.6;
	cursor: default;
}

ngb-pagination.adn-paginator .page-item.active .page-link {
	border: none;
	background-color: $primary;
	color: #fff !important;
}

ngb-pagination.adn-paginator .page-item.active .page-link:focus, .page-link:not(:disabled):not(.disabled):active:focus {
	box-shadow: 0 0 0 0.1rem $primary;
}

ngb-pagination.adn-paginator .page-link:not(:disabled):not(.disabled):active:focus {
	box-shadow: 0 0 0 0.1rem $primary;
}

.adn-pag-right {
	display: flex;
	color: #70737e !important;
	span {
		margin-top: 6px;
	}
	button {
		margin-right: 8px;
		margin-left: 8px;
		height: 35px;
		padding: 9px 11px;
		border-radius: $border-radius;
		margin-top: -3px;
		span {
			padding: 0;
			margin-right: -6px;
		}
	}
}
